import { TypeManagerDecorator } from "../../main/type.map.service";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { SpreadsheetComponent } from "./s25.spreadsheet.component";
import { jSith } from "../../util/jquery-replacement";

@TypeManagerDecorator("s25-ng-spreadsheet-contextmenu")
@Component({
    selector: "s25-ng-spreadsheet-contextmenu",
    template: `
        <ul class="ngSpreadsheetContextMenu">
            <li class="ngCpointer ngListStyleNone" (click)="addRowAbove()">Add Row Above</li>
            <li class="ngCpointer ngListStyleNone" style="padding-top: 3px;" (click)="addRowBelow()">Add Row Below</li>
            <li class="ngCpointer ngListStyleNone" style="padding-top: 3px;" (click)="removeRow()">Remove Row</li>
            <li class="ngCpointer ngListStyleNone" style="padding-top: 3px;" (click)="addColumnLeft()">
                Add Column Left
            </li>
            <li class="ngCpointer ngListStyleNone" style="padding-top: 3px;" (click)="addColumnRight()">
                Add Column Right
            </li>
            <li class="ngCpointer ngListStyleNone" style="padding-top: 3px;" (click)="removeColumn()">Remove Column</li>
        </ul>
    `,
    styles: `
        ul.ngSpreadsheetContextMenu {
            background: #d1e7f4ff;
            padding: 10px;
            border-radius: 8px;
            width: 10em;
            height: 145px;
            font-size: small;
        }

        ul.ngSpreadsheetContextMenu > li:hover {
            background: #b9daee;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpreadsheetContextmenuComponent implements OnInit {
    constructor(
        private cd: ChangeDetectorRef,
        private elem: ElementRef,
    ) {
        this.elem.nativeElement.addEventListener("contextmenu", (evt: any) => {
            evt.preventDefault();
            evt.stopPropagation();
            return false;
        });
    }

    @Input() spreadsheet: SpreadsheetComponent;

    addRowAbove = () => {
        this.spreadsheet.addRow(-1, this.spreadsheet.contextMenuCell);
    };

    addRowBelow = () => {
        this.spreadsheet.addRow(1, this.spreadsheet.contextMenuCell);
    };

    removeRow = () => {
        this.spreadsheet.removeRow(this.spreadsheet.contextMenuCell);
    };

    addColumnLeft = () => {
        this.spreadsheet.addColumn(-1, this.spreadsheet.contextMenuCell);
    };

    addColumnRight = () => {
        this.spreadsheet.addColumn(1, this.spreadsheet.contextMenuCell);
    };

    removeColumn = () => {
        this.spreadsheet.removeColumn(this.spreadsheet.contextMenuCell);
    };

    show = (left: number, top: number) => {
        jSith.setCss(this.elem.nativeElement, {
            left: left,
            top: top,
            display: "block",
            position: "absolute",
            "z-index": 100,
        });
        this.cd.detectChanges();
    };

    hide = () => {
        jSith.setCss(this.elem.nativeElement, {
            display: "none",
        });
        this.cd.detectChanges();
    };

    ngOnInit(): void {
        this.hide();
    }
}
