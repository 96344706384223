import { TypeManagerDecorator } from "../../main/type.map.service";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { S25File } from "../s25-file-upload/s25.file.upload.component";
import { RowData, Stox } from "./spreadsheet.types";
import { jSith } from "../../util/jquery-replacement";
import Table from "@wolf-table/table";
import { DataCell, IndexDataCell } from "@wolf-table/table/dist/data";
import { DomResp, DomService } from "../../services/dom.service";
import { SpreadsheetContextmenuComponent } from "./s25.spreadsheet.contextmenu.component";

declare global {
    interface Window {
        XLSX: {
            read: (data: string | ArrayBuffer) => Table;
        };
        stox: (workbook: any) => Stox;
    }
}

@TypeManagerDecorator("s25-ng-spreadsheet")
@Component({
    selector: "s25-ng-spreadsheet",
    template: `
        <s25-ng-file-upload
            [onAdd]="handleFileUpload"
            [readAsArrayBuffer]="true"
            [showFiles]="false"
        ></s25-ng-file-upload>
        @if (onComplete) {
            <button
                (click)="handleComplete()"
                (keyup.enter)="handleComplete()"
                class="ngBlock ngFloatRight aw-button aw-button--primary c-margin-top--single c-margin-bottom--single"
            >
                {{ completeText }}
            </button>
        }
        <div [hidden]="!uploaded" class="ngHeight100 ngWidth100 c-margin-top--single" #spreadsheetEl></div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpreadsheetComponent implements OnInit, OnDestroy {
    static scriptsPromise: Promise<any> = null;

    @ViewChild("spreadsheetEl") spreadsheetEl: ElementRef<HTMLDivElement>;

    @Input() onUpload?: (rows: RowData[]) => void;
    @Input() onComplete?: (rows: RowData[]) => void;
    @Input() completeText?: string = "Done";

    spreadsheet: Table;
    init = false;
    uploaded = false;
    spreadsheetCreated = false;
    spreadsheetWidth = 1400;
    spreadsheetHeight = 600;
    contextMenu: DomResp<SpreadsheetContextmenuComponent>;
    contextMenuCell: any;
    rows = 0;
    minCols = 26; // one col per alpha letter

    constructor(
        private cd: ChangeDetectorRef,
        private domService: DomService,
    ) {}

    handleComplete = () => {
        this.onComplete && this.onComplete(this.getSheetData());
    };

    handleFileUpload = (file: S25File) => {
        this.uploaded = true;
        this.cd.detectChanges();
        SpreadsheetComponent.scriptsPromise.then(() => {
            if (!this.spreadsheetCreated) {
                this.spreadsheetCreated = true;
                this.spreadsheet = Table.create(
                    this.spreadsheetEl.nativeElement,
                    () => this.spreadsheetWidth,
                    () => this.spreadsheetHeight,
                    {
                        scrollable: true,
                        resizable: true,
                        selectable: true,
                        editable: true,
                        copyable: false,
                    },
                );

                this.domService
                    .appendComponentToBody(SpreadsheetContextmenuComponent, {
                        spreadsheet: this,
                    })
                    .then((domResp) => {
                        this.contextMenu = domResp;
                        this.spreadsheet.onClick((cell, evt) => {
                            const { x, y, width, height } = cell;
                            if (x > 0 && y > 0 && evt.button === 2) {
                                // not a click on row numbers or column letters and a right click / contextmenu
                                evt.stopPropagation();
                                evt.preventDefault();
                                this.contextMenuCell = cell;
                                let offset = this.spreadsheet.container().offset();
                                this.contextMenu.instance.show(x + offset.x, y + offset.y);
                            } else {
                                this.contextMenu.instance.hide();
                            }
                            return false;
                        });

                        this.spreadsheet._container._.addEventListener("contextmenu", (evt) => {
                            evt.preventDefault();
                            evt.stopPropagation();
                            return false;
                        });

                        // fix for https://github.com/wolf-table/table/issues/14
                        let editor = this.spreadsheet._editors.get("text");
                        let origMoveChange = editor._moveChanger;
                        editor.moveChanger((direction: any) => {
                            origMoveChange("none");
                        });

                        setInterval(() => {
                            // fixes weird bug where grid freezes when scrolling sometimes
                            this.spreadsheet._vScrollbar._content._.parentElement.style.display = "";
                        }, 1000);
                    });
            }

            this.processWorkbook(window.XLSX.read(file.data));
            this.cd.detectChanges();
        });
    };

    processWorkbook = (workbook: Table) => {
        let xsprData: Stox = window.stox(workbook);
        let rowColCells: IndexDataCell[] = [];

        if (xsprData && xsprData.length && xsprData[0].rows) {
            let rowLen = xsprData[0].rows.len || 0;
            let realRowLen = 0;
            for (let r = 0; r < rowLen; r++) {
                let row = xsprData[0].rows[r];

                let maxColNum = 0;
                jSith.forEach(row.cells, (colNum, colVal) => {
                    if (colNum > maxColNum) {
                        maxColNum = colNum;
                    }
                });

                if (maxColNum === 0) {
                    break;
                }

                realRowLen++;

                for (let c = 0; c <= Math.max(maxColNum, this.minCols); c++) {
                    if (c > maxColNum) {
                        rowColCells.push([r, c, ""]);
                    } else {
                        let cell = row.cells[c];
                        rowColCells.push([r, c, cell.text]);
                    }
                }
            }

            this.spreadsheetHeight = Math.min(600, (realRowLen + 1) * 27);

            // fix for https://github.com/wolf-table/table/issues/15
            this.rows = realRowLen;
            this.spreadsheet.data().rows.len = this.rows;
            this.spreadsheet._renderer.rows(this.rows);
            this.spreadsheet._cells._indexes.clear();
            this.spreadsheet.data({ cells: rowColCells });
            this.spreadsheet.resize(); // calls render

            // start at top of grid
            this.spreadsheet._vScrollbar.scrollToStart();

            console.log(this.spreadsheet);
        }

        this.onUpload && this.onUpload(this.getSheetData());
        this.cd.detectChanges();
    };

    addRow = (dir: number, cell: { row: number; col: number }) => {
        let rowColCells: IndexDataCell[] = [];
        let newRowOffset = 0;
        let belowPositionFound = false;
        let sheetData = this.getSheetData();
        for (let rowNum = 0; rowNum < sheetData.length; rowNum++) {
            if (rowNum === cell.row || belowPositionFound) {
                // found cell's row
                let colLen = sheetData[rowNum]?.cells?.length || 0;
                if (dir < 0) {
                    // new row above cell
                    for (let colNum = 0; colNum < colLen; colNum++) {
                        rowColCells.push([rowNum, colNum, ""]);
                    }
                    newRowOffset++;
                    belowPositionFound = false;
                } else {
                    // new row below cell: continue to writing this row and make the new row on the next iteration
                    dir = -1;
                    belowPositionFound = true;
                }
            }

            let colLen = sheetData[rowNum]?.cells?.length || 0;
            for (let colNum = 0; colNum < colLen; colNum++) {
                let rowColCell: IndexDataCell = [
                    rowNum + newRowOffset,
                    colNum,
                    sheetData[rowNum].cells[colNum]?.text ?? "",
                ];
                rowColCells.push(rowColCell);
            }
        }

        this.rows++;
        this.resizeWithNewData(rowColCells);
        this.contextMenu.instance.hide();
        this.cd.detectChanges();
    };

    removeRow = (cell: { row: number; col: number }) => {
        if (this.rows <= 1) {
            this.contextMenu.instance.hide();
            return;
        }

        let rowColCells: IndexDataCell[] = [];
        let removedRowOffset = 0;
        let sheetData = this.getSheetData();
        let maxColNum = this.getMaxColNum();

        for (let rowNum = 0; rowNum < sheetData.length; rowNum++) {
            if (rowNum === cell.row) {
                // found cell's row
                removedRowOffset++;
                continue;
            }
            for (let colNum = 0; colNum < Math.max(maxColNum, this.minCols); colNum++) {
                let rowColCell: IndexDataCell;
                if (colNum >= maxColNum) {
                    rowColCell = [rowNum, colNum, ""];
                } else {
                    rowColCell = [rowNum - removedRowOffset, colNum, sheetData[rowNum].cells[colNum]?.text ?? ""];
                }
                rowColCells.push(rowColCell);
            }
        }

        this.rows--;
        this.resizeWithNewData(rowColCells);
        this.contextMenu.instance.hide();
        this.cd.detectChanges();
    };

    addColumn = (dir: number, cell: { row: number; col: number }) => {
        let rowColCells: IndexDataCell[] = [];
        let newColOffset = 0;
        let rightPositionFound = false;
        let sheetData = this.getSheetData();
        let maxColNum = this.getMaxColNum();

        for (let colNum = 0; colNum < maxColNum; colNum++) {
            if (colNum === cell.col || rightPositionFound) {
                // found cell's col
                if (dir < 0) {
                    // new col left of cell
                    for (let rowNum = 0; rowNum < sheetData.length; rowNum++) {
                        rowColCells.push([rowNum, colNum, ""]);
                    }
                    newColOffset++;
                    rightPositionFound = false;
                } else {
                    // new col right of cell: continue to writing this col and make the new col on the next iteration
                    dir = -1;
                    rightPositionFound = true;
                }
            }

            for (let rowNum = 0; rowNum < sheetData.length; rowNum++) {
                let rowColCell: IndexDataCell = [
                    rowNum,
                    colNum + newColOffset,
                    sheetData[rowNum].cells[colNum]?.text ?? "",
                ];
                rowColCells.push(rowColCell);
            }
        }

        this.resizeWithNewData(rowColCells);
        this.contextMenu.instance.hide();
        this.cd.detectChanges();
    };

    removeColumn = (cell: { row: number; col: number }) => {
        let rowColCells: IndexDataCell[] = [];
        let removedColOffset = 0;
        let sheetData = this.getSheetData();
        let maxColNum = this.getMaxColNum();

        for (let colNum = 0; colNum <= Math.max(maxColNum, this.minCols); colNum++) {
            if (colNum === cell.col) {
                // found cell's row
                removedColOffset++;
                continue;
            }
            for (let rowNum = 0; rowNum < sheetData.length; rowNum++) {
                let rowColCell: IndexDataCell;
                if (colNum >= maxColNum) {
                    rowColCell = [rowNum, colNum, ""];
                } else {
                    rowColCell = [rowNum, colNum - removedColOffset, sheetData[rowNum].cells[colNum]?.text ?? ""];
                }
                rowColCells.push(rowColCell);
            }
        }

        this.resizeWithNewData(rowColCells);
        this.contextMenu.instance.hide();
        this.cd.detectChanges();
    };

    resizeWithNewData = (rowColCells: IndexDataCell[]) => {
        rowColCells.sort((a, b) => {
            return parseFloat(a[0] + "." + a[1]) - parseFloat(b[0] + "." + b[1]);
        });
        this.spreadsheet.data().rows.len = this.rows;
        this.spreadsheet._renderer.rows(this.rows);
        this.spreadsheet._contentRect.height = this.spreadsheet.data().rows.len * this.spreadsheet._data.rowHeight;
        this.spreadsheet._cells._indexes.clear();
        this.spreadsheet.data({ cells: rowColCells });
        this.spreadsheet.resize(); // calls render

        // fixes weird bug where grid freezes when removing a row past what the initial row count was
        this.spreadsheet._vScrollbar._content._.parentElement.style.display = "";
    };

    getSheetData = (): RowData[] => {
        let rows: RowData[] = [];
        let rowNum = this.getMaxRowNum();
        let colNum = this.getMaxColNum();
        for (let r = 0; r < rowNum; r++) {
            let row: RowData = { cells: [] };
            for (let c = 0; c < colNum; c++) {
                let cell = this.spreadsheet._cells.get(r, c);
                if (cell) {
                    let dataCell: DataCell = cell[2];
                    let dataVal = dataCell instanceof Object ? dataCell.value : dataCell;
                    row.cells.push({ text: S25Util.toStr(dataVal) });
                }
            }
            rows.push(row);
        }
        return rows;
    };

    getMaxRowNum = () => {
        let maxRowNum = 0;
        for (let cell of this.spreadsheet.data().cells) {
            let rowNum = cell[0] + 1;
            if (rowNum > maxRowNum) {
                maxRowNum = rowNum;
            }
        }
        return maxRowNum;
    };

    getMaxColNum = () => {
        let maxColNum = 0;
        for (let cell of this.spreadsheet.data().cells) {
            if (cell[2]) {
                let colNum = cell[1] + 1;
                if (colNum > maxColNum) {
                    maxColNum = colNum;
                }
            }
        }
        return maxColNum;
    };

    ngOnInit() {
        SpreadsheetComponent.scriptsPromise =
            SpreadsheetComponent.scriptsPromise ||
            S25Util.all([]).then(async () => {
                await S25Util.loadScript(
                    "xlsx-shim-js",
                    "https://cdn.sheetjs.com/xlsx-latest/package/dist/shim.min.js",
                );
                await S25Util.loadScript(
                    "xlsx-full-js",
                    "https://cdn.sheetjs.com/xlsx-latest/package/dist/xlsx.full.min.js",
                );
                return S25Util.loadScript(
                    "xlsx-spreadsheet-js",
                    "https://cdn.sheetjs.com/xspreadsheet/xlsxspread.min.js",
                );
            });
        SpreadsheetComponent.scriptsPromise.then(() => {
            this.init = true;
            this.cd.detectChanges();
        });
    }

    ngOnDestroy() {
        this.contextMenu?.destroy();
    }
}
