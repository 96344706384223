import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    EventEmitter,
    Output,
} from "@angular/core";
import { S25ItemI } from "../../pojo/S25ItemI";
import { Table } from "../s25-table/Table";
import { Bind } from "../../decorators/bind.decorator";
import { CacheRepository } from "../../decorators/cache.decorator";
import { StandardScheduleService } from "../../services/standard.schedule.service";
import { S25Util } from "../../util/s25-util";
import { S25TableComponent } from "../s25-table/s25.table.component";

@Component({
    selector: "s25-ng-standard-schedule-patterns",
    template: `
        <s25-ng-table
            [dataSource]="tableData"
            [hasRefresh]="true"
            [hasFilter]="true"
            [columnSortable]="true"
            [pivotThreshold]="800"
        ></s25-ng-table>
    `,
    styles: `
        ::ng-deep .s25ngTable .rose-object-table-header {
            margin-left: 0.625rem;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25StandardSchedulePatterns implements OnChanges {
    @Input() items: S25ItemI[];
    @Input() options = { showScheduleName: false };
    @Output() patternsCount = new EventEmitter<number>();

    tableData: Table.DataSource;

    @ViewChild(S25TableComponent) table: S25TableComponent;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    async refresh(force?: boolean) {
        this.tableData = this.getTableConfig();
        await this.table?.refresh(force);
        this.cd.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.refresh();
    }

    getTableConfig(): Table.DataSource {
        let rows: Table.DataSource = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                {
                    id: "start_time",
                    header: "Start Time",
                },
                {
                    id: "end_time",
                    header: "End Time",
                },
                {
                    id: "dow",
                    header: "Days",
                },
                {
                    id: "dur",
                    header: "Minutes Duration",
                },
            ],
        };
        this.options.showScheduleName &&
            rows.columns.push({
                id: "schedule",
                header: "Schedule",
            });
        return rows;
    }

    @Bind
    async getRows(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        this.items = S25Util.array.forceArray(this.items);
        if (query.forceRefresh) CacheRepository.invalidateByService("StandardScheduleService");
        let schedules = await StandardScheduleService.getStandardSchedule(
            this.items.map((item) => S25Util.parseInt(item.itemId)),
        );
        schedules = S25Util.array.forceArray(schedules).map((pattern) => {
            return { ...pattern, itemId: pattern.schedule_id, itemName: pattern.schedule_name };
        });
        const retSchedules = schedules.map(this.mapToRow) || [];
        this.patternsCount.emit(retSchedules.length);
        return {
            rows: retSchedules,
        };
    }

    mapToRow(item: any): Table.Row {
        return {
            id: item.id,
            name: item.dow,
            cells: {
                start_time: { text: S25Util.timeConvert(parseInt(item.start_time)) },
                end_time: { text: S25Util.timeConvert(parseInt(item.end_time)) },
                dow: { text: item.dow },
                dur: { text: S25Util.timesDuration(item.start_time, item.end_time) },
                schedule: { text: item.itemName },
            },
        };
    }
}
