import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25TableModule } from "../s25-table/s25.table.module";
import { S25ModalModule } from "../s25-modal/s25.modal.module";
import { S25RichTextEditorModule } from "../s25-rich-text-editor/s25.rich.text.editor.module";
import { S25AddressModule } from "../s25-address/s25.address.module";
import { S25ImageSelectorModule } from "../s25-image-selector/s25.image.selector.module";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { S25StandardScheduleListComponent } from "./s25.standard.schedule.list.component";
import { S25StandardSchedulePatterns } from "./s25.standard.schedule.patterns";

@NgModule({
    declarations: [S25StandardScheduleListComponent, S25StandardSchedulePatterns],
    imports: [
        CommonModule,
        FormsModule,
        S25TableModule,
        S25ModalModule,
        S25RichTextEditorModule,
        S25AddressModule,
        S25ImageSelectorModule,
        S25ImageSelectorModule,
        S25EditableModule,
        S25CheckboxModule,
        S25DropdownPaginatedModule,
        S25ToggleButtonModule,
    ],
    providers: [S25StandardScheduleListComponent, S25StandardSchedulePatterns],
    exports: [S25StandardScheduleListComponent, S25StandardSchedulePatterns],
})
export class S25StandardScheduleModule {
    constructor() {}
}
