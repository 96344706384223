import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SpreadsheetComponent } from "./s25.spreadsheet.component";
import { FileUploadModule } from "../s25-file-upload/s25.file.upload.module";
import { SpreadsheetContextmenuComponent } from "./s25.spreadsheet.contextmenu.component";

@NgModule({
    declarations: [SpreadsheetComponent, SpreadsheetContextmenuComponent],
    imports: [CommonModule, FormsModule, FileUploadModule],
    providers: [SpreadsheetComponent, SpreadsheetContextmenuComponent],
    exports: [SpreadsheetComponent, SpreadsheetContextmenuComponent],
})
export class SpreadsheetModule {
    constructor() {}
}
